<template>
    <div class="columns is-centered has-text-centered has-dbl-space-above">
      <div class="column is-narrow" v-if="prev.text">
        <span class="icon has-text-primary"><font-awesome-icon :icon="['far', 'arrow-to-left']" transform="grow-2 down-2"/></span> <router-link class="is-size-5" :to="prev.href">{{prev.text}}</router-link></div>
      <div class="column is-narrow" v-if="next.text">
        <router-link class="is-size-5" :to="next.href">{{next.text}} </router-link> <span class="icon has-text-primary"><font-awesome-icon :icon="['far', 'arrow-to-right']"  transform="grow-2 down-2"/></span>
      </div>
    </div>
</template>

<script>
  // get some convenience functions e.g. displayDate and convertToHTML
  import misc from '../mixins/misc'
  import {mapGetters, mapActions} from 'vuex'


  export default {
    name: 'BookNav',
    mixins: [misc],
    props: {
      bookname: String //for an individual item we only look at the slug to find it
    },

    data: function () {
      return {
        prev: {text:"", href:""},
        next: {text:"", href:""},
      }
    },

    created() {
      this.$log.debug(`{BookNav} getBookContents called for book ${this.bookname}`);
      let that=this;
      // getBookContents is an async action so when it is fulfilled we know we can initialise the nav
      this.getBookContents(this.bookname)
        .then(function(res) {
          let nav = that.bookNav(that.bookname);
          // this.$log.debug('{created bookNav} nav:', nav, {});
          that.prev= nav.prev;
          that.next= nav.next;
        });
    },

    computed: {
      // mix this into the outer object with the object spread operator
      ...mapGetters('books',[
        'bookNav'
      ])
    },

    methods: {
      ...mapActions('books',[
        'getBookContents' //get a books contents list
      ])
    }

  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    /*@import "~bulma/sass/utilities/mixins";*/
    /*@import "@/scss/_bulmaOverridden";*/

</style>
